var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('SpinnerLogo', {
    staticClass: "pt-5"
  }) : _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("Add new targets, or edit and remove existing targets.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-primary",
          on: {
            "click": function ($event) {
              _vm.modals.create = true;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("New Target")])];
      },
      proxy: true
    }])
  }, [[_vm._v("Manage")]], 2), _c('Modal', {
    attrs: {
      "id": "delete",
      "open": _vm.modals.create,
      "title": "Create Target"
    },
    on: {
      "close": function ($event) {
        _vm.modals.create = false;
      },
      "submit": _vm.onCreateTarget
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "label": "Name",
      "type": "text",
      "alt": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }